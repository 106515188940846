import { FamilyRight } from 'src/app/models/family';

export enum ActionTypes {
    LOAD_FAMILY_RIGHT = '[Family] Load Family Right',
    SUCCESS_FAMILY_RIGHT = '[Family] Success Family Right',
    SUCCESS_UPDATE_FAMILY_RIGHT = '[Family] Success Update Family Right',
    ERROR_FAMILY_RIGHT = '[Family] Error Family Right'
}

export class LoadFamilyRight {
    readonly type = ActionTypes.LOAD_FAMILY_RIGHT;
}

export class SuccessFamilyRight {
    readonly type = ActionTypes.SUCCESS_FAMILY_RIGHT;
    constructor(public payload: Array<FamilyRight>) {
    }
}

export class SuccessUpdateFamilyRight {
    readonly type = ActionTypes.SUCCESS_UPDATE_FAMILY_RIGHT;
    constructor(public payload: FamilyRight) {
    }
}

export class ErrorFamilyRight {
    readonly type = ActionTypes.ERROR_FAMILY_RIGHT;
}

export type Actions =
    LoadFamilyRight |
    SuccessFamilyRight |
    ErrorFamilyRight |
    SuccessUpdateFamilyRight;
