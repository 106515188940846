import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Family, FamilyRight } from '../models/family';
import { PictureService } from '../models/scope-management.model';

export interface CreateOrUpdateFamily {
    id?: string;
    name: string;
    description: string;
    capacity?: number;
    compatibleSection?: string[];
    pn: string[];
    isJig?: boolean;
    category: string;
    signalSource?: string[];
    isNotManagedByWay?: boolean;
    minMaxTime: number;
    isAirplan?: boolean;
}

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class FamilyService implements PictureService {

    constructor(private http: HttpClient) { }

    public getFamilies(): Observable<Family[]> {
        return this.http.get<Family[]>(
            `${environment.API_ENDPOINT}/families`
        );
    }

    public getFamiliesRights(): Observable<FamilyRight[]> {
        return this.http.get<FamilyRight[]>(
            `${environment.API_ENDPOINT}/families-rights `
        );
    }

    public getUserFamiliesRights(userId: string): Observable<FamilyRight[]> {
        return this.http.get<FamilyRight[]>(
            `${environment.API_ENDPOINT}/families-rights/users/${userId}`
        );
    }

    public getPnByFamilies(familyId: string): Observable<string[]> {
        return this.http.get<string[]>(`${environment.API_ENDPOINT}/families/${familyId}/pn`);
    }

    public getPicture(familyId: string): Observable<{ picture: string }> {
        return this.http.get<{ picture: string }>(`${environment.API_ENDPOINT}/families/${familyId}/picture`);
    }

    public createPictureFamily(picture: string | ArrayBuffer, familyId: string): Observable<Family> {
        return this.http.post<Family>(
            `${environment.API_ENDPOINT}/families/${familyId}/picture`,
            { picture: picture },
            httpOptions
        );
    }

    public deletePictureFamily(familyId: string): Observable<Family> {
        return this.http.delete<Family>(`${environment.API_ENDPOINT}/families/${familyId}/picture`);
    }

    public createFamily(family: CreateOrUpdateFamily, parentFamilyId: string): Observable<Family> {
        const body = { ...family, parentFamilyId: parentFamilyId };
        return this.http.post<Family>(
            `${environment.API_ENDPOINT}/families`,
            JSON.stringify(body),
            httpOptions
        );
    }

    public updateFamily(family: CreateOrUpdateFamily, familyId: string): Observable<Family> {
        return this.http.put<Family>(
            `${environment.API_ENDPOINT}/families/${familyId}`,
            JSON.stringify(family),
            httpOptions
        );
    }

    public deleteFamily(familyId: string): Observable<Family> {
        return this.http.delete<Family>(`${environment.API_ENDPOINT}/families/${familyId}`);
    }
}
