<div class="way-table--main-container">

    <div class="table-container custom-scrollbar">
        <table class="way-table"
               mat-table
               matSort
               [dataSource]="dataSource"
               [attr.aria-describedby]="'ARIA_LABEL_GROUP_TABLE_USERS' | translate"
               recycleRows>
            <ng-container matColumnDef="userId">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{ "NAME" | translate }}
                </th>
                <td mat-cell
                    *matCellDef="let user">
                    {{ user.userId }}
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{ "EMAIL" | translate }}
                </th>
                <td mat-cell
                    *matCellDef="let user">
                    {{ user.email }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell
                    *matHeaderCellDef>
                    <div class="actions">
                        <button mat-icon-button
                                *ngIf="selection.selected.length"
                                [matTooltip]="('REMOVE_SELECTED_USERS' | translate) + ' ('+ selection.selected.length + ')'"
                                [disabled]="disabled || usersLoading"
                                (click)="onClickRemoveMass()">
                            <fa-icon icon="trash"></fa-icon>
                        </button>
                        <button #addUserButton
                                mat-icon-button
                                class="action-button"
                                [disabled]="disabled || usersLoading"
                                [matTooltip]="'ADD_USERS' | translate"
                                (click)="onClickAdd()"
                                [ngClass]="{'highlight': !disabled && !usersLoading && users?.length === 0}">
                            <fa-icon icon="plus"></fa-icon>
                        </button>
                    </div>
                </th>
                <td mat-cell
                    *matCellDef="let user">
                    <div class="actions">
                        <mat-checkbox [disabled]="disabled"
                                      color="primary"
                                      (change)="selection.toggle(user)"></mat-checkbox>
                        <button mat-icon-button
                                class="action-button"
                                [disabled]="disabled"
                                (click)="onClickRemove(user)">
                            <fa-icon icon="trash"></fa-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>
    <!-- Loading indicator -->
    <ng-container *ngIf="usersLoading">
        <div class="loading-spinner">
            <mat-progress-spinner color="primary"
                                  mode="indeterminate"
                                  diameter="32"></mat-progress-spinner>
        </div>
    </ng-container>
    <!-- Paginatorè -->
    <ng-container>
        <mat-paginator
                    class="way-table--footer"
                    showFirstLastButtons
                    [attr.aria-label]="'SELECT_PAGE_OF_TABLE' | translate"
                    (page)="scrollUp()"
                    [pageSizeOptions]="[10, 25, 50]">
        </mat-paginator>
    </ng-container>
</div>
<!-- Empty status -->
<ng-container *ngIf="!usersLoading && !dataSource.data.length">
    <div class="no-data">
        <img src="assets/img/undraw_add_friends_re_3xte.png"
             alt="Illustration add users">
        <span class="status">{{ "MANAGE_GROUPS_NO_USER" | translate }}</span>
        <span class="hint">{{ "HINT_ADD_USER" | translate }}</span>
    </div>
</ng-container>
