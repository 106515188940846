import { Action, createReducer, on } from '@ngrx/store';
import { ProfileFamily, ProfileParentFamily, UserRights } from 'src/app/models/profile';
import { addFamilyProfile, addParentFamilyProfile, errorFetchAllProfiles, fetchAllProfiles, successFetchAllProfiles, updateAssetCount, updateIsOversizeRight } from '../actions/profile.action';
import { RoleLevelEnum } from 'src/app/models/user-right';

export interface State {
  data: {
    profiles: {
      families: ProfileFamily[],
      parent_families: ProfileParentFamily[],
      userRights: UserRights,
      context: string;
      assetCount: number,
    },
  };
  loading: boolean;
  loaded: boolean;

}

const initialState: State = {
  data: {
    profiles: {
      families: [],
      parent_families: [],
      userRights: {
        isAdmin: false,
        isOversize: false,
        isPlanningManager: false,
        isPlanningManagerAct: false,
        isMobileAdmin: false,
        isGroupAdmin: false,
        scope: [],
        isZoneAdmin: false,
        isPositionAdmin: false,
        oversize_user: false,
        topLevel: RoleLevelEnum.basic,
      },
      context: null,
      assetCount: 0
    },
  },
  loading: false,
  loaded: false,
};

const profileReducer = createReducer(
  initialState,
  on(fetchAllProfiles, (state): State => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(successFetchAllProfiles, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      data: (() => {
        const profile = action.profile;
        const newStateData = {
          profiles: {
            families: profile.families,
            parent_families: profile.parentFamilies,
            userRights: profile.userRights,
            context: profile.selectedContext,
            assetCount: profile.assetCount,
        }};
        return newStateData;
      })()
    };
  }),
  on(errorFetchAllProfiles, (state): State => {
    return {
      ...state,
      loading: false,
      loaded: true
    };
  }),
  on(addFamilyProfile, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      data: (() => {
        const newStateData = { ...state.data };
        newStateData.profiles.families.push({ id: action.profileFamily.id, level: action.profileFamily.level, parent: action.profileFamily.parent });
        return newStateData;
      })()
    };
  }),
  on(addParentFamilyProfile, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      data: (() => {
        const newStateData = { ...state.data };
        newStateData.profiles.parent_families.push({ id: action.profileParentFamily.id, level: action.profileParentFamily.level, parent: action.profileParentFamily.parent });
        return newStateData;
      })()
    };
  }),
  on(updateIsOversizeRight, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      data: (() => {
        const newStateData = { ...state.data };
        newStateData.profiles.userRights.isOversize = action.payload;
        return newStateData;
      })()
    };
  }),
  on(updateAssetCount, (state, action) => ({
    ...state,
    data: ((data) => {
        data.profiles.assetCount += (action.added ?? 0);
        data.profiles.assetCount -= (action.removed ?? 0);
        return data;
      })(state.data)
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return profileReducer(state, action);
}
