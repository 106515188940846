<form [formGroup]="formGroup">
    <mat-form-field appearance="outline">
        <mat-label>{{ 'PARENT_FAMILY' | translate }}</mat-label>
        <mat-select formControlName="parentFamily">
            <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                        [formControl]="searchForm.controls.parentFamily"
                                        [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                </ngx-mat-select-search>
            </mat-option>
            <mat-option>
                <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
            </mat-option>
            <mat-option *ngFor="let parentFamily of parentFamilies$
                                | async
                                | listFilter:'name':searchForm.value.parentFamily:true:true
                                | sort:false:'name';
                                trackBy: trackById"
                        [value]="parentFamily.id">
                {{ parentFamily.name }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.controls.parentFamily.errors?.required">
            <fa-icon icon="circle-exclamation"></fa-icon>
            {{ "REQUIRED_SELECTION" | translate }}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>{{ 'ASSET_FAMILY' | translate }}</mat-label>
        <mat-select formControlName="family">
            <mat-option>
                <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                        [formControl]="searchForm.controls.family"
                                        [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                </ngx-mat-select-search>
            </mat-option>
            <mat-option>
                <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
            </mat-option>
            <mat-option *ngFor="let family of families$
                                | async
                                | listFilter:'parentFamilyId':formGroup.value.parentFamily
                                | listFilter:'name':searchForm.value.family:true:true
                                | sort:false:'name';
                                trackBy: trackById"
                        [value]="family.id">
                {{ family.name }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.controls.family.errors?.required">
            <fa-icon icon="circle-exclamation"></fa-icon>
            {{ "REQUIRED_SELECTION" | translate }}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>{{ "MSN" | translate }}</mat-label>
        <input matInput
               formControlName="msn">
        <mat-error *ngIf="formGroup.controls.msn.errors?.required">
            <fa-icon icon="circle-exclamation"></fa-icon>
            {{ "REQUIRED_INPUT" | translate }}
        </mat-error>
    </mat-form-field>
    <mat-radio-group formControlName="side">
        <mat-radio-button value="G">{{ 'LEFT' | translate | titlecase }}</mat-radio-button>
        <mat-radio-button value="D">{{ 'RIGHT' | translate | titlecase }}</mat-radio-button>
    </mat-radio-group>
</form>
