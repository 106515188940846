import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { CompanyService } from 'src/app/services/company.service';
import * as CompanyAction from '../actions/company.actions';
import { successUpdateSites } from '../actions/site.action';
import { selectSitesByCompanyIds$ } from '../selectors/site.selectors';

@Injectable()
export class CompanyEffects {
    public fetchAllCompanies$ = createEffect(() => { return this._actions$.pipe(
        ofType(CompanyAction.fetchAllCompanies),
        switchMap(() => this._companyService.getCompanies()),
        map((companies) => CompanyAction.successFetchAllCompanies({ payload: companies })),
        catchError(() => of(CompanyAction.errorFetchAllCompanies()))
    ) });

    public successUpdateCompanies$ = createEffect(() => { return this._actions$.pipe(
        ofType(CompanyAction.successUpdateCompanies),
        // update children
        switchMap((action) => this.store.select(selectSitesByCompanyIds$(action.payload.map(company => company.id))).pipe(
            take(1),
            map((sites) => successUpdateSites({
                payload: sites,
            }))
        )),
    ) });

    constructor(
        private _companyService: CompanyService,
        private _actions$: Actions,
        private store: Store
        ,
    ) {
    }
}
