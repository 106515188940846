import { RoleLevel, RoleLevelEnum } from './user-right';

export interface Profile {
    validation: boolean;
    is_first_login: boolean;
    isMobileUser: boolean;
    isAdminMobile: boolean;
    families: ProfileFamily[];
    parentFamilies: ProfileParentFamily[];
    userRights: UserRights;
    selectedContext: string;
    assetCount: number; // Number of assets
}

export interface ProfileFamily {
    id: string;
    level: RoleLevel;
    parent: string;
}

export interface ProfileParentFamily {
    id: string;
    level: RoleLevel;
    parent: string;
}

export interface UserRights {
    isAdmin: boolean;
    isOversize: boolean;
    isPlanningManager?: boolean;
    isPlanningManagerAct?: boolean;
    isMobileAdmin?: boolean;
    isZoneAdmin?: boolean;
    isPositionAdmin?: boolean;
    isGroupAdmin?: boolean;
    scope: Array<Scope>;
    topLevel: RoleLevelEnum;
    oversize_user?: boolean;
}

export enum Scope {
    LOG = 'log',
    OVERSIZE = 'oversize',
    I3M = 'i3m',
    ACT = 'act',
    CATracking = 'caTracking',
}
