<div class="way-component--filterable-container">

  <!-- Filter -->
  <div class="way-filter-container">
    <app-filter [parentSourceView]="'manageGroups'"
                [dataForFilter$]="groups$"></app-filter>
  </div>

  <div class="panel">

    <div class="way-component--header">
      <div class="way-component--header-title">{{"MANAGE_GROUPS" | translate}}</div>
      <div class="way-component--header-buttons">
        <button mat-flat-button
                color="primary"
                (click)="onClickCreate()">
          <fa-icon icon="plus"></fa-icon>
          {{ 'MANAGE_GROUPS_CREATE' | translate }}
        </button>
        <button mat-stroked-button
                color="primary"
                (click)="refreshData()">
          <fa-icon icon="sync-alt"></fa-icon>
          {{ 'REFRESH_DATA' | translate }}
        </button>
      </div>
    </div>

  </div>

  <div class="way-table--main-container">

    <div class="table-container custom-scrollbar">

      <table mat-table
             matSort
             [dataSource]="dataSource"
             class="way-table"
             aria-label="manage group table">

        <ng-container matColumnDef="name">
          <th scope="col"
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef>{{ "NAME" | translate }}</th>
          <td mat-cell
              *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th scope="col"
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef>{{ "DESCRIPTION" | translate }}</th>
          <td mat-cell
              *matCellDef="let element">{{ element.description }}</td>
        </ng-container>

        <ng-container matColumnDef="quantityOfUsers">
          <th scope="col"
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef>{{ "QUANTITY_OF_USERS" | translate }}</th>
          <td mat-cell
              *matCellDef="let element">{{ element.quantityOfUsers }}</td>
        </ng-container>

        <ng-container matColumnDef="quantityOfFamilies">
          <th scope="col"
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef>{{ "QUANTITY_OF_FAMILIES" | translate }}</th>
          <td mat-cell
              *matCellDef="let element">{{ element.quantityOfFamilies }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th scope="col"
              mat-header-cell
              *matHeaderCellDef></th>
          <td mat-cell
              *matCellDef="let element"
              class="action-column">
            <button mat-icon-button
                    [matMenuTriggerFor]="groupActionMenu"
                    (click)="$event.stopPropagation()">
              <fa-icon icon="ellipsis-v"></fa-icon>
            </button>
            <mat-menu #groupActionMenu="matMenu">
                <button mat-menu-item
                        (click)="onClickModifyGroup(element)">
                    <fa-icon icon="pencil-alt"
                             [fixedWidth]="true"></fa-icon>
                    {{"EDIT" | translate}}
                </button>
                <button mat-menu-item
                        class="warn"
                        (click)="onClickDeleteGroup(element)">
                    <fa-icon icon="trash"
                             [fixedWidth]="true"></fa-icon>
                    {{"DELETE" | translate}}
                </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

      <div *ngIf="isLoadingTable$ | async"
           class="table-loader">
        <mat-progress-spinner color="primary"
                              mode="indeterminate"
                              diameter="32">
        </mat-progress-spinner>
      </div>

    </div>

    <mat-paginator class="way-table--footer"
                   showFirstLastButtons
                   [attr.aria-label]="'SELECT_PAGE_OF_TABLE' | translate"
                   (page)="scrollUp()"
                   [pageSizeOptions]="[25, 50, 100]">
    </mat-paginator>

  </div>

</div>
