<div class="way-component--filterable-container">

        <div class="way-component--header">
            <div class="way-component--header-title">{{"MANAGE_RIGHT_REQUEST" | translate}}</div>
        </div>

        <div class="way-table--main-container">
            <div class="table-container custom-scrollbar">
                <table class="way-table" #table mat-table matSort (matSortChange)="sortData($event)"
                    [dataSource]="dataSource" [attr.aria-label]="'ARIA_RIGHT_REQUEST_TABLE' | translate">
                    <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns">
                        <th mat-header-cell mat-sort-header [disabled]="column === 'actionColumns' ? true : false"
                            *matHeaderCellDef scope="col">
                            {{ getRealName(column) }}</th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element">
                                <div [ngSwitch]="column">
                                    <ng-container *ngSwitchCase="'level'">
                                        <mat-chip *ngIf="element[column] && element[column] !== 'basic'"
                                            class="{{element[column]}}">
                                            {{ element[column] | translate | uppercase }}
                                        </mat-chip>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'user'">
                                        <div>
                                            <!-- {{element.requesterFirstname}} {{element.requesterLastname}} {{element.requesterMail}} -->
                                            {{element.requesterMail}}
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'date'">
                                        <div>
                                            {{(element.date | typeOf) === 'date' ? (element.date | moment:'L LT') : element.date}}
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'actionColumns'">
                                        <div class="infos-actionColumns">
                                            <button *ngIf="!element.isLoading; else actionLoader" mat-icon-button
                                                [matMenuTriggerFor]="actionMenu">
                                                <fa-icon icon="ellipsis-v" class="table--action-icon"></fa-icon>
                                            </button>

                                            <mat-menu #actionMenu="matMenu" xPosition="before">
                                                <div class="action-menu">
                                                    <button mat-menu-item (click)="onClickAcceptDemand(element)">
                                                        <fa-icon icon="check"
                                                                 [fixedWidth]="true"></fa-icon>
                                                        {{"MANAGE_RIGHT_ACCEPT" | translate}}
                                                    </button>
                                                    <button mat-menu-item class="warn"
                                                        (click)="onClickRefuseDemand(element)">
                                                        <fa-icon icon="times"
                                                                 [fixedWidth]="true"></fa-icon>
                                                        {{"MANAGE_RIGHT_REFUSE" | translate}}
                                                    </button>
                                                </div>
                                            </mat-menu>
                                        </div>

                                        <ng-template #actionLoader>
                                            <span>
                                                <mat-progress-spinner color="primary" mode="indeterminate" diameter="16"
                                                    class="row-loader">
                                                </mat-progress-spinner>
                                            </span>
                                        </ng-template>

                                    </ng-container>



                                    <ng-container *ngSwitchDefault>
                                        <div>
                                            {{ element[column] }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky : true"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="table-row"></tr>
                </table>

                <div *ngIf="isLoadingTable" class="table-loader">
                    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
                    </mat-progress-spinner>
                </div>

                <div *ngIf="isNoData" class="table-loader">
                    {{"MANAGE_RIGHT_NO_REQUESTS" | translate}}
                </div>
            </div>
            <mat-paginator class="way-table--footer" (page)="scrollUp()" [pageSizeOptions]="[25, 50, 100]">
            </mat-paginator>
        </div>

</div>
