import { createAction, props } from '@ngrx/store';
import { FamilyRight } from 'src/app/models/family';
import { Site } from 'src/app/models/site';
import { Zone, ZoneUpdate } from 'src/app/models/zone';

export const fetchAllZones = createAction('[Zone] fetch all Zones');
export const successFetchAllZones = createAction('[Zone] Success fetch all Zones', props<{ zones: Array<Zone>, sites: Array<Site>, families: Array<FamilyRight> }>());
export const errorFetchAllZones = createAction('[Zone] Error Zones');

export const updateZone = createAction('[Zone] edit zone', props<{ zoneId: string, zone: ZoneUpdate }>());
export const successUpdateZone = createAction('[Zone] Success edit zone', props<{ zone: Zone }>());
export const errorUpdateZone = createAction('[Zone] Error edit zone');

export const mapZonesFamilies = createAction('[Zone] Success map zone families', props<{ zones: Array<Zone>, families: Array<FamilyRight> }>());
