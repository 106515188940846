import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of, Subject } from 'rxjs';
import { EffectResult } from './asset.effects';
import { createTracker, errorUpdateTrackers, fetchAllTrackers, mappingAllTrackersAssets, mappingSomeTrackersAssets, successAssociateTrackerAsset, successCreateTrackers, successDissociateTrackerAsset, successFetchAllTrackers, successUpdateTrackers, updateTrackers } from '../actions/tracker.action';
import { catchError, delay, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { TrackerService } from 'src/app/services/tracker.service';
import { selectAssetsById$, selectAssetState$ } from '../selectors/asset.selectors';
import { selectTrackerById$ } from '../selectors/tracker.selectors';
import { mappingAssetsWithTrackersData, __successAssociateAssetTracker, __successDissociateAssetTracker, successAssociateTrackerToNewAsset, deleteAsset, successCreateAssets } from '../actions/asset.actions';
import { Tracker } from 'src/app/models/tracker';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from 'src/app/components/shared/snackbar/snackbar';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { noop } from '../actions/_shared.action';
import { contextIsValid$, selectUserSelectedContext$ } from '../selectors/filter-context.selectors';

@Injectable()
export class TrackerEffects {
    public fetchAllTrackers$ = createEffect(() => { return this._actions$.pipe(
        ofType(fetchAllTrackers),
        switchMap(() => this._trackerService.getTrackersByRights()),
        map((trackers) => successFetchAllTrackers({ trackers }))
    ) });

    public successFetchAllTrackers$ = createEffect(() => { return this._actions$.pipe(
        ofType(successFetchAllTrackers),
        switchMap(action => this.store.select(selectAssetState$).pipe(
            filter(state => state.loaded),
            take(1),
            delay(500),
            tap(() => this.store.dispatch(mappingAssetsWithTrackersData({ trackers: action.trackers }))),
            map(state => mappingAllTrackersAssets({ assets: state.data.assets, trackers: action.trackers }))
        ))
    ) });

    public createtracker$ = createEffect(() => { return this._actions$.pipe(
        ofType(createTracker),
        switchMap((action) => this._trackerService.createTracker(action.payload)),
        map((tracker) => successCreateTrackers({ payload: [tracker] }))
    ) });

    public createTrackerWithAsset$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(successCreateAssets),
            map(({ payload }) => {
                const asset = payload[0];
                if (asset.tracker) {
                    return successCreateTrackers({
                        payload: [asset.tracker],
                    })
                }
                return noop();
            })
        )
    })

    public successCreateOrUpdateTrackers$ = createEffect(() => { return this._actions$.pipe(
        ofType(successCreateTrackers, successUpdateTrackers),
        switchMap(({ payload }) => combineLatest([
            this.store.select(contextIsValid$),
            this.store.select(selectUserSelectedContext$),
            this.store.select(selectAssetState$),
        ]).pipe(
            filter(([valid, , { loaded }]) => valid && loaded),
            take(1),
            map(([, context, state]) => {
                let trackers = payload;
                if (context.providers?.length) {
                    // No filtering done on providers => asset can be created
                    trackers = payload.filter(
                        ({ provider }) => context.providers.includes(provider)
                    );
                }
                return {
                    trackers,
                    assets: state.data.assets,
                };
            }),
            tap(() => {
                this.store.dispatch(mappingAssetsWithTrackersData({ trackers: payload }));
            }),
            map(({ trackers, assets }) => {
                if (trackers.length) {
                    return mappingSomeTrackersAssets({ assets, trackers });
                }
                return noop();
            }),
        )),
    ) });

    public successAssociateTrackerAsset$ = createEffect(() => { return this._actions$.pipe(
        ofType(successAssociateTrackerAsset),
        switchMap((action) => combineLatest([
            this.store.select(selectAssetsById$(action.assetId)),
            this.store.select(selectTrackerById$(action.trackerId))
        ]).pipe(take(1))),
        tap(([asset, tracker]) => this.store.dispatch(__successAssociateAssetTracker({ assetId: asset.id, tracker: tracker }))),
        map(([asset, tracker]) => mappingSomeTrackersAssets({
            assets: { [asset.id]: asset },
            trackers: [{ ...tracker, associated: true, assetId: asset.id }]
        }))
    ) });

    public successDissociateTrackerAsset$ = createEffect(() => { return this._actions$.pipe(
        ofType(successDissociateTrackerAsset),
        map(action => __successDissociateAssetTracker({ assetId: action.assetId }))
    ) });

    public updateTrackers$ = createEffect(() => { return this._actions$.pipe(
        ofType(updateTrackers),
        switchMap((action) => this._trackerService.updateTracker(action.tracker, action.trackerId).pipe(
            map((updatedTracker: Tracker) => {
                const text: string = this._translate.instant('BANNER_SUCCESS_RANGE', { value: updatedTracker.serial });
                this._snackBar.open(text, 'green-snackbar', 5000);
                this.effectSubject.next('success');
                return successUpdateTrackers({ payload: [updatedTracker] });
            }),
            catchError((err) => {
                this._errorService.handleError(err, 'BANNER_FAIL_EDIT');
                this.effectSubject.next('error');
                return of(errorUpdateTrackers());
            })
        )),
    ) });

    // Dissociate tracker when an asset is deleted
    public deleteAsset$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(deleteAsset),
            filter(({ asset }) => !!asset.trackerId),
            map(({ asset }) => successDissociateTrackerAsset({
                assetId: asset.id,
                trackerId: asset.tracker.id,
            })),
        );
    });

    public successAssociateTrackerToNewAsset$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(successAssociateTrackerToNewAsset),
            map(({ asset, tracker }) => {
                if (asset.tracker?.id) {
                    return successAssociateTrackerAsset({
                        assetId: asset.id,
                        trackerId: tracker.id,
                    });
                }
            }),
        );
    });

    public effectSubject: Subject<EffectResult>;
    constructor(
        private _trackerService: TrackerService,
        private _actions$: Actions,
        private store: Store,
        private _snackBar: SnackbarComponent,
        private _translate: TranslateService,
        private _errorService: ErrorTranslationService,
    ) {
        this.effectSubject = new Subject<EffectResult>();
    }
}
