import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoGuide, VideoGuideComponent } from '../video-guide/video-guide.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError } from 'rxjs';
import { ContentLoaderService } from 'src/app/services/shared/content-loader.service';
import { environment } from 'src/environments/environment.default';
import { ShowConditionsOfUseComponent } from '../show-conditions-of-use/show-conditions-of-use.component';
import { SnackbarComponent } from '../snackbar/snackbar';
import { ListItem } from './mobile-bottom-sheet/mobile-bottom-sheet.component';

export enum ContentType {
    guide = 'menu_book',
    legal = 'gavel',
    lesson = 'school',
    support = 'contact_support',
    video = 'movie',
}

@Injectable({
  providedIn: 'root'
})
export class HelpListService {

    private readonly _E_LEARNING_LINK = 'https://airbus.csod.com/LMS/LoDetails/DetailsLo.aspx?loid=6f52e896-b727-4193-a3f7-828183986909';
    private readonly _SUPPORT_LINK = 'https://airbus.service-now.com/welcome';
    private readonly _PDF_MAIN_MODULE_PATH = 'User-Guide-W@Y-english.pdf';
    private readonly _PDF_ANALYTICS_MODULE_PATH = 'User-Guide-W@Y-KPI.pdf';
    private readonly _PDF_DEVICE_HARDWARE_MODULE_PATH = 'W@Y-User-Manual-for-Device-Hardware.pdf';
    private readonly _pdfAdsSupportManualPath = 'W@Y-Support-Manual.pdf';

    public readonly HELP_LIST: ListItem<ContentType>[] = [
        {
            icon: ContentType.guide,
            titleI18n: 'USER_GUIDE',
            subtitleI18n: 'USER_GUIDE_SUBTITLE',
            callback: () => this.requestAndOpenLink(this._PDF_MAIN_MODULE_PATH),
        },
        {
            icon: ContentType.guide,
            titleI18n: 'USER_GUIDE_ANALYTIC',
            subtitleI18n: 'USER_GUIDE_ANALYTIC_SUBTITLE',
            callback: () => this.requestAndOpenLink(this._PDF_ANALYTICS_MODULE_PATH),
        },
        {
            icon: ContentType.guide,
            titleI18n: 'VIDEO_TUTORIAL_ANALYTICS_MODULE',
            subtitleI18n: 'VIDEO_TUTORIAL_ANALYTICS_MODULE_SUBTITLE',
            callback: () => this.openGuideVideo(VideoGuide.kpi),
        },
        {
            icon: ContentType.video,
            titleI18n: 'VIDEO_TUTORIAL_MAP_TRACKING',
            subtitleI18n: 'VIDEO_TUTORIAL_MAP_TRACKING_SUBTITLE',
            callback: () => this.openGuideVideo(VideoGuide.map),
        },
        {
            icon: ContentType.video,
            titleI18n: 'VIDEO_TUTORIAL_ALERTS_MODULE',
            subtitleI18n: 'VIDEO_TUTORIAL_ALERTS_MODULE_SUBTITLE',
            callback: () => this.openGuideVideo(VideoGuide.alert),
        },
        {
            icon: ContentType.video,
            titleI18n: 'VIDEO_TUTORIAL_ADMIN_MODULE',
            subtitleI18n: 'VIDEO_TUTORIAL_ADMIN_MODULE_SUBTITLE',
            callback: () => this.openGuideVideo(VideoGuide.admin),
        },
        {
            icon: ContentType.lesson,
            titleI18n: 'E_LEARNING',
            link: this._E_LEARNING_LINK,
        },
        {
            icon: ContentType.guide,
            titleI18n: 'USER_GUIDE_DEVICE_HARDWARE',
            subtitleI18n: 'USER_GUIDE_DEVICE_HARDWARE_SUBTITLE',
            callback: () => this.requestAndOpenLink(this._PDF_DEVICE_HARDWARE_MODULE_PATH),
        },
        {
            icon: ContentType.legal,
            titleI18n: 'CONDITIONS_OF_USE',
            subtitleI18n: 'CONDITIONS_OF_USE_SUBTITLE',
            callback: () => this.openConditionsOfUse(),
        },
        {
            icon: ContentType.support,
            titleI18n: 'CONTACT_SUPPORT',
            subtitleI18n: 'CONTACT_SUPPORT_SUBTITLE',
            callback: () => this.getSupportLink(),
        },
    ];

    constructor(
        private readonly translate: TranslateService,
        private readonly _snackBar: SnackbarComponent,
        private readonly _contentLoaderService: ContentLoaderService,
        // private readonly _renderer: Renderer2,
        private readonly dialog: MatDialog,
        private readonly _domSanitizer: DomSanitizer,
    ) {
        this.HELP_LIST.forEach(
            (item) => {
                if (item.link) {
                    item.link = this._domSanitizer.sanitize(SecurityContext.URL, item.link);
                }
            }
        )
    }

    requestAndOpenLink(requestedFile: string): void {
        this._snackBar.open(this.translate.instant('REQUEST_LINK_IN_PROGRESS'), 'green-snackbar', Infinity);
        this._contentLoaderService.getFileUrl(requestedFile, 'pdf').pipe(
            catchError(
                () => EMPTY
            ),
        ).subscribe(
            (data) => {
                this._snackBar.dismiss();
                if (!data?.message) {
                    this._snackBar.open(this.translate.instant('ERROR_REQUEST_LINK'), 'red-snackbar');
                }
                // Open link in new tab
                window.open(data.message, '_blank');
            }
        );
    }

    /**
     * Open a guide video dialog
     * @param videoGuide KPI, MAP, ALERT, ADMIN to choose the correct video
     */
    public openGuideVideo(videoGuide: VideoGuide) {
        this.dialog.open(VideoGuideComponent, {
            maxWidth: '95%',
            width: '800px',
            autoFocus: false,
            data: videoGuide,
        });
    }

    /**
     * Open a popup containing the application conditions of use
     */
    public openConditionsOfUse() {
        this.dialog.open(ShowConditionsOfUseComponent, {
            autoFocus: false,
            data: { validation: true },
        });
    }

    /**
     * @returns Return the support link regarding the environment as a string
     */
    public getSupportLink() {
        this._contentLoaderService.getFileUrl(this._pdfAdsSupportManualPath, 'pdf').subscribe((data) => {
            const href = environment.envName === 'ads' ? data.message : this._SUPPORT_LINK;
            window.open(href, '_blank');
        });
    }
}
