<div mat-dialog-title>
    <span>{{ "DIALOG_GENERATE_EVENT" | translate }}</span>
    <button mat-icon-button
            mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<form [formGroup]="eventForm"
      (ngSubmit)="generateEvent()">
    <div class="custom-scrollbar"
         mat-dialog-content>
        <mat-form-field appearance="outline">
            <mat-label>{{ 'PROVIDER' | translate }}</mat-label>
            <mat-select formControlName="supplier">
                <mat-option>
                    <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                           [formControl]="searchForm.controls.supplier"
                                           [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                    <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                </mat-option>
                <mat-option *ngFor="let signalSource of SignalSource
                                    | keyvalue
                                    | listFilter:'value':searchForm.value.supplier:true:true
                                    | sort:false:'value'"
                            [value]="SignalSource[signalSource.key]">
                    {{ SignalSource[signalSource.key] }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="eventForm.controls.supplier.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_SELECTION" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'ASSET' | translate }}</mat-label>
            <mat-select formControlName="asset">
                <mat-select-trigger>
                    <!-- Display the name of the selected asset -->
                    {{ assets$ | async | listFilter:'id':eventForm.value.asset | extractPropertyPipe:'name' }}
                </mat-select-trigger>
                <mat-option>
                    <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                           [formControl]="searchForm.controls.asset"
                                           [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                    <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                </mat-option>
                <mat-option *ngFor="let asset of assets$
                            | async
                            | listFilter:'name':searchForm.value.asset:true:true
                            | sort:false:'name'
                            | slice:0:200; trackBy: trackById"
                            [value]="asset.id">
                    <span>
                        {{ asset.name }}<br>
                        <small>{{ asset.family.parent_family.name }} / {{ asset.family?.name }}</small>
                    </span>
                </mat-option>
            </mat-select>
            <mat-hint>
                <ng-container *ngIf="(loadings.assets$ | async) === true; else hintTemplate">
                    <mat-progress-spinner mode="indeterminate"
                                          diameter="12"
                                          strokeWidth="2"></mat-progress-spinner>
                    {{ "LOADING_DATA" | translate }}
                </ng-container>
                <ng-template #hintTemplate>
                    {{ "LIST_LIMITED_QTY" | translate:{number:'200'} }}
                </ng-template>
            </mat-hint>
            <mat-error *ngIf="(loadings.assets$ | async) === false && eventForm.controls.asset.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_SELECTION" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'TYPE' | translate }}</mat-label>
            <mat-select formControlName="type">
                <mat-option>
                    <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                           [formControl]="searchForm.controls.type"
                                           [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                    <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                </mat-option>
                <mat-option *ngFor="let eventType of EventType
                                    | keyvalue
                                    | listFilter:'value':searchForm.value.type:true:true
                                    | sort:false:'value'"
                            [value]="eventType.key">
                    {{ EventType[eventType.key] }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="eventForm.controls.type.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_SELECTION" | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="eventForm.value.supplier === SignalSource.aswarm"
                        appearance="outline">
            <mat-label>{{ 'STATUS' | translate }}</mat-label>
            <mat-select formControlName="status">
                <mat-option>
                    <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                           [formControl]="searchForm.controls.status"
                                           [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option>
                    <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                </mat-option>
                <mat-option *ngFor="let status of Status
                                    | keyvalue
                                    | listFilter:'value':searchForm.value.type:true:true
                                    | sort:false:'value'"
                            [value]="status.key">
                    {{ Status[status.key] | translate }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="eventForm.controls.status.errors?.required">
                <fa-icon icon="circle-exclamation"></fa-icon>
                {{ "REQUIRED_SELECTION" | translate }}
            </mat-error>
        </mat-form-field>

        <!-- Depending on the select event type -->
        <ng-container [ngSwitch]="eventForm.value.type">
            <ng-container *ngSwitchCase="EventType.data">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'DATA' | translate }}</mat-label>
                    <textarea matInput
                              formControlName="data"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="5"></textarea>
                    <mat-error *ngIf="eventForm.controls.data.errors?.required">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ "REQUIRED_INPUT" | translate }}
                    </mat-error>
                </mat-form-field>
            </ng-container>
            <ng-container *ngSwitchCase="EventType.location">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'LATITUDE' | translate }}</mat-label>
                    <input matInput
                           formControlName="latitude"
                           type="number">
                    <mat-icon matSuffix>height</mat-icon>
                    <mat-error *ngIf="eventForm.controls.latitude.errors?.min || eventForm.controls.latitude.errors?.max">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ 'ERROR_RANGE_VALUE' | translate:{min: '-90', max: '90'} }}
                    </mat-error>
                    <mat-error *ngIf="eventForm.controls.latitude.errors?.required">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ "REQUIRED_INPUT" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'LONGITUDE' | translate }}</mat-label>
                    <input matInput
                           formControlName="longitude"
                           type="number">
                    <mat-icon matSuffix>360</mat-icon>
                    <mat-error *ngIf="eventForm.controls.longitude.errors?.min || eventForm.controls.longitude.errors?.max">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ 'ERROR_RANGE_VALUE' | translate:{min: '-180', max: '180'} }}
                    </mat-error>
                    <mat-error *ngIf="eventForm.controls.longitude.errors?.required">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ "REQUIRED_INPUT" | translate }}
                    </mat-error>
                </mat-form-field>
            </ng-container>
            <ng-container *ngSwitchCase="EventType.state">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATE' | translate }}</mat-label>
                    <mat-select formControlName="state">
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                                   [formControl]="searchForm.controls.state"
                                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option>
                            <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                        </mat-option>
                        <mat-option *ngFor="let state of State
                                            | keyvalue
                                            | listFilter:'value':searchForm.value.state:true:true
                                            | sort:false:'value'"
                                    [value]="State[state.key]">
                            {{ State[state.key] | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="eventForm.controls.state.errors?.required">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ "REQUIRED_SELECTION" | translate }}
                    </mat-error>
                </mat-form-field>
            </ng-container>
            <ng-container *ngSwitchCase="EventType.zone">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'ZONE' | translate }}</mat-label>
                    <mat-select>
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                                   [formControl]="searchForm.controls.zone"
                                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option>
                            <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                        </mat-option>
                        <mat-option *ngFor="let zone of zones$
                                                        | async
                                                        | listFilter:'name':searchForm.value.zone:true:true
                                                        | sort:false:'name'; trackBy: trackById"
                                    [value]="zone.id">
                            {{ zone.name }}
                        </mat-option>
                    </mat-select>
                    <mat-hint *ngIf="(loadings.zones$ | async) === true">
                        <mat-progress-spinner mode="indeterminate"
                                              diameter="12"
                                              strokeWidth="2"></mat-progress-spinner>
                        {{ "LOADING_DATA" | translate }}
                    </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'STATE' | translate }}</mat-label>
                    <mat-select formControlName="state">
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                                   [formControl]="searchForm.controls.state"
                                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option>
                            <i>-- {{ 'CLEAR_SELECTION' | translate }} --</i>
                        </mat-option>
                        <mat-option *ngFor="let state of State
                                            | keyvalue
                                            | listFilter:'value':searchForm.value.state:true:true
                                            | sort:false:'value'"
                                    [value]="State[state.key]">
                            {{ State[state.key] | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="(loadings.zones$ | async) === false && eventForm.controls.state.errors?.required">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ "REQUIRED_SELECTION" | translate }}
                    </mat-error>
                    <mat-error *ngIf="eventForm.controls.zone.errors?.required">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ "REQUIRED_SELECTION" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'ACCURACY' | translate }}</mat-label>
                    <input matInput
                           formControlName="accuracy"
                           type="number">
                    <mat-error *ngIf="eventForm.controls.accuracy.errors?.min">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ 'ERROR_MIN_VALUE' | translate:{min: '0'} }}
                    </mat-error>
                    <mat-error *ngIf="eventForm.controls.accuracy.errors?.required">
                        <fa-icon icon="circle-exclamation"></fa-icon>
                        {{ "REQUIRED_INPUT" | translate }}
                    </mat-error>
                </mat-form-field>
            </ng-container>
        </ng-container>
    </div>
    <div mat-dialog-actions>
        <button type="button"
                mat-stroked-button
                mat-dialog-close
                color="primary">
            {{ "CANCEL" | translate }}
        </button>
        <button type="submit"
                mat-flat-button
                color="primary"
                [disabled]="eventForm.invalid || loadings.submit">
            {{ "Generate" }}
            <mat-progress-spinner *ngIf="loadings.submit"
                                  mode="indeterminate"
                                  diameter="16"
                                  strokeWidth="2">
            </mat-progress-spinner>
        </button>
    </div>
</form>
