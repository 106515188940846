import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '../models/company';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {


    constructor(private http: HttpClient) { }

    public getCompanies(): Observable<Company[]> {
        return this.http.get<Company[]>(`${environment.API_ENDPOINT}/companies`);
    }
}
