import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Site } from '../models/site';

@Injectable({
    providedIn: 'root'
})
export class SiteService {

    constructor(private http: HttpClient) { }

    /**
     * Get all sites for a specific company
     * @param companyId The id of the company
     * @returns List of Site model
     */
    public getSites(companyId: string): Observable<Site[]> {
        return this.http.get<Site[]>(`${environment.API_ENDPOINT}/companies/${companyId}/sites`);
    }

    /**
     * Get all sites
     */
    public getAllSites(): Observable<Site[]> {
        return this.http.get<Site[]>(`${environment.API_ENDPOINT}/sites`);
    }
}
