import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Family, ParentFamily, ParentFamilyRight } from '../models/family';

export interface UpdateParentFamily {
    name: string;
    description: string;
}

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class ParentFamilyService {

    constructor(private http: HttpClient) { }

    public getParentFamiliesByCompanyAndSite(companyId, siteId): Observable<ParentFamily[]> {
        return this.http.get<ParentFamily[]>(`${environment.API_ENDPOINT}/companies/${companyId}/sites/${siteId}/parent-families`);
    }

    public getParentFamilies(): Observable<ParentFamily[]> {
        return this.http.get<ParentFamily[]>(`${environment.API_ENDPOINT}/parent-families`);
    }

    public getAllParentFamilies(): Observable<ParentFamily[]> {
        return this.http.get<ParentFamily[]>(`${environment.API_ENDPOINT}/public-parent-families`);
    }

    public getFamiliesByParentFamily(parentFamily: string): Observable<Family[]> {
        return this.http.get<Family[]>(`${environment.API_ENDPOINT}/parent-families/${parentFamily}/families`);
    }

    public getParentFamiliesRights(): Observable<ParentFamilyRight[]> {
        return this.http.get<ParentFamilyRight[]>(`${environment.API_ENDPOINT}/parent-families-rights`);
    }

    public createParentFamily(parentFamily: ParentFamily): Observable<ParentFamily> {
        const body = parentFamily;
        return this.http.post<ParentFamily>(
            `${environment.API_ENDPOINT}/parent-families`,
            body,
            httpOptions
        );
    }

    public updateParentFamily(parentFamily: UpdateParentFamily, parentFamilyId: string): Observable<ParentFamily> {
        return this.http.put<ParentFamily>(
            `${environment.API_ENDPOINT}/parent-families/${parentFamilyId}`,
            parentFamily,
            httpOptions
        );
    }

    public deleteParentFamily(parentFamilyId: string): Observable<ParentFamily> {
        return this.http.delete<ParentFamily>(
            `${environment.API_ENDPOINT}/parent-families/${parentFamilyId}`
        );
    }
}
