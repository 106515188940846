<!-- Header -->
<span class="title">{{ "DIALOG_MANAGE_ASSOCIATIONS" | translate }}</span>

<mat-stepper orientation="vertical"
             linear>
    <mat-step [stepControl]="trackerSelectionForm">
        <ng-template matStepLabel>{{ "DEVICE_SELECTION" | translate }}</ng-template>
        <form [formGroup]="trackerSelectionForm">
            <label for="input-quickasso-device-SN"
                   class="caption">
                {{ 'DIALOG_MANAGE_ASSOCIATIONS_DEVICE_SN' | translate }}
            </label>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'DEVICE_SERIAL_NUMBER' | translate }}</mat-label>
                <input matInput
                       formControlName="tracker"
                       type="text"
                       [matAutocomplete]="auto" />
                <mat-icon matSuffix
                          *ngIf="trackerSelectionForm.value.tracker"
                          (click)="trackerSelectionForm.controls.tracker.reset()">
                    close
                </mat-icon>
                <mat-hint *ngIf="checkTrackerValidity">
                    <mat-progress-spinner mode="indeterminate"
                                          diameter="12"
                                          strokeWidth="2"></mat-progress-spinner>
                    {{ "CHECKING_SERIAL_VALIDITY" | translate }}
                </mat-hint>
                <mat-error *ngIf="trackerSelectionForm.controls.tracker.errors?.required">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ "REQUIRED_INPUT" | translate }}
                </mat-error>
                <mat-error *ngIf="trackerSelectionForm.controls.tracker.errors?.linkError">
                    <fa-icon icon="circle-exclamation"></fa-icon>
                    {{ trackerSelectionForm.controls.tracker.errors?.linkError | translate }}
                </mat-error>
                <mat-autocomplete autoActiveFirstOption
                                  #auto="matAutocomplete">
                    <ng-container *ngFor="let option of trackers$
                                            | async
                                            | listFilter:'serial':trackerSelectionForm.value.tracker:false:false
                                            | sort:false:'serial'">
                        <mat-option [value]="option.serial">
                            {{option.serial}}
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>
            <button mat-flat-button
                    matStepperNext
                    color="primary"
                    [disabled]="trackerSelectionForm.pristine || trackerSelectionForm.invalid || checkTrackerValidity">
                {{ "NEXT" | translate }}
            </button>
        </form>
    </mat-step>
    <mat-step #configureAssetStep *ngIf="(selectedTracker$ | async)?.associated === false"
              [stepControl]="assoManagementForm">
        <ng-template matStepLabel>{{ "DEVICE_ASSOCIATION_CONFIGURE_ASSET" | translate }}</ng-template>
        <form [formGroup]="assoManagementForm">
            <label class="caption">
                {{ 'DIALOG_QUICK_ASSO_DISSO_ASSO_VALIDATION' | translate }}
            </label>
            <app-tracker-associate [tracker]="selectedTracker$ | async"
                                   [formGroup]="assoManagementForm"></app-tracker-associate>
            <button mat-stroked-button
                    type="button"
                    color="primary"
                    matStepperPrevious>
                {{ "BACK" | translate }}
            </button>
            <button mat-flat-button
                    type="button"
                    matStepperNext
                    [disabled]="assoManagementForm.invalid"
                    color="primary">
                {{ "NEXT" | translate }}
            </button>
        </form>
    </mat-step>
    <mat-step #reviewStep>
        <ng-template matStepLabel>{{ "REVIEW_CHANGES" | translate }}</ng-template>
        <ng-container *ngIf="(selectedTracker$ | async) as tracker">
            <form>
                <ng-container *ngIf="tracker.associated; else assoTemplate">
                    <ng-container *ngIf="(assetOfSelectedTracker$ | async) as asset; else dissoForbidden">
                        <label class="title">{{ "DIALOG_UNLINK_TITLE" | translate }}</label>
                        <div class="block">
                            <span>
                                {{ "DIALOG_MANAGE_ASSOCIATIONS_UNLINK" | translate:{ deviceId: tracker.serial } }}
                            </span>
                            <ul>
                                <li>{{ "ASSET_NAME" | translate }}: {{ asset.name }}</li>
                                <li>{{ "ASSET_FAMILY" | translate }}: {{ asset.family?.name }}</li>
                            </ul>
                            <p>{{ "DIALOG_UNLINK_VALIDATION" | translate }}</p>
                        </div>
                    </ng-container>
                    <ng-template #dissoForbidden>
                        <p>{{ "DIALOG_QUICK_ASSO_DISSO_NOT_AUTHORIZED_TO_DISSO" | translate }}</p>
                    </ng-template>
                </ng-container>
                <ng-template #assoTemplate>
                    <label class="title">{{ "DIALOG_LINK_TITLE" | translate }}</label>
                    <div class="block">
                        <span>
                            {{ "MANAGE_ASSOCIATIONS_LINK_REVIEW" | translate:{ deviceId: tracker.serial } }}
                        </span>
                        <ul>
                            <li>{{ "ASSET_NAME" | translate }}: {{ assoManagementForm.value.msn }}-{{ assoManagementForm.value.side }}</li>
                            <li>{{ "ASSET_FAMILY" | translate }}: {{ assoManagementForm.value.family }}</li>
                        </ul>
                        <p>{{ "DIALOG_MANAGE_ASSOCIATIONS_LINK_CONFIRMATION" | translate }}</p>
                        <p>{{ "DIALOG_MANAGE_ASSOCIATIONS_LINK_CONFIRMATION_MSG" | translate }}</p>
                    </div>
                </ng-template>
                <button mat-stroked-button
                        type="button"
                        color="primary"
                        matStepperPrevious>
                    {{ "BACK" | translate }}
                </button>
                <button mat-flat-button
                        type="button"
                        (click)="complete()"
                        color="primary">
                    {{ "CONTINUE" | translate }}
                </button>
            </form>
        </ng-container>
    </mat-step>
</mat-stepper>
