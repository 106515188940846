import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-empty-state-fill-field',
  templateUrl: './empty-state-fill-field.component.html',
  styleUrls: ['./empty-state-fill-field.component.scss']
})
export class EmptyStateFillFieldComponent {

    @Input() title: string = this._translate.instant('MANAGE_RIGHT_FILL_PANEL');
    @Input() subtitle: string;

    constructor(
        private _translate: TranslateService,
    ) {}

}
