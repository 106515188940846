import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { RoleLevelEnum } from 'src/app/models/user-right';

export const selectProfileState$ = (state: AppState) => state.profiles;

export const selectProfiles$ = createSelector(selectProfileState$, (profiles) => profiles.data);

export const selectProfilesLoading$ = createSelector(selectProfileState$, (profiles) => profiles.loading);

export const selectProfilesLoaded$ = createSelector(selectProfileState$, (profiles) => profiles.loaded);

export const selectProfileFamilies$ = createSelector(selectProfileState$, (profiles) => Object.values(profiles.data.profiles.families));

export const selectProfileAssetCount$ = createSelector(selectProfileState$, (profiles) => profiles.data.profiles.assetCount);

export const selectProfileParentFamilies$ = createSelector(selectProfileState$, (profiles) => Object.values(profiles.data.profiles.parent_families));

export const selectProfileFamilyIdByLevels$ = (levels) => createSelector(selectProfileState$, (profiles) => {
    if (profiles) {
        return profiles.data.profiles.families.filter((family) => levels.indexOf(family.level) > -1).map((family) => family.id);
    }
});

export const selectProfileParentFamilyIdByLevels$ = (levels) => createSelector(selectProfileState$, (profiles) => {
    if (profiles) {
        return profiles.data.profiles.parent_families.filter((parent_family) => levels.indexOf(parent_family.level) > -1).map((parent_family) => parent_family.id);
    }
});

export const selectProfileUserRights$ = createSelector(selectProfileState$, (profiles) => profiles.data.profiles.userRights);

export const selectProfileUserRightsLoaded$ = createSelector(selectProfileState$, (profiles) => profiles.loaded);

export const selectProfileUserRightsTopLevel$ = createSelector<AppState, AppState['profiles'], RoleLevelEnum>(selectProfileState$, (userRights) => {
    if (userRights) {
        return userRights.data.profiles.userRights.isAdmin ? RoleLevelEnum.administrator : userRights.data.profiles.userRights.topLevel;
    }
    return RoleLevelEnum.basic;
});

export const selectProfileContext$ = createSelector(selectProfileState$, (state) => state.data.profiles.context);
