import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, combineLatest } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as featureActions from '../actions/familyRight.action';
import { TranslateService } from '@ngx-translate/core';
import { FamilyService } from 'src/app/services/family.service';
import { FamilyRight } from 'src/app/models/family';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { successCreateFamilies } from '../actions/family.action';
import { Store } from '@ngrx/store';
import { selectProfileParentFamilies$ } from '../selectors/profile.selectors';
import { selectParentFamilyById$ } from '../selectors/parent-family.selectors';

@Injectable()
export class FamilyRightEffects {
    public LoadFamilyRight$: Observable<featureActions.Actions> = createEffect(() => {
        return this._actions$.pipe(
            ofType<featureActions.LoadFamilyRight>(featureActions.ActionTypes.LOAD_FAMILY_RIGHT),
            switchMap(() => this._familyService.getFamiliesRights().pipe(
                map((families: Array<FamilyRight>) => {
                    return new featureActions.SuccessFamilyRight(families);
                }),
                catchError((error) => {
                    this._errorTranslationService.handleError(error, this.translate.instant('BANNER_FAIL_FETCH_RIGHTS'));
                    return of(new featureActions.ErrorFamilyRight());
                }))
            )
        )
    });

    public updateUserRightOnFamilyCreation$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(successCreateFamilies),
            switchMap(({ family }) => combineLatest([
                this.store.select(selectProfileParentFamilies$),
                this.store.select(selectParentFamilyById$(family.parentFamilyId)),
            ]).pipe(
              map(([rights, parentFamily]) => {
                    const parentFamilyRight = rights.find(
                        ({ id }) => id === family.parentFamilyId
                    );
                    return new featureActions.SuccessUpdateFamilyRight({
                        id: family.id,
                        level: 'manager',
                        name: family.name,
                        parentFamily: {
                            id: parentFamily.id,
                            company: parentFamily.site?.company,
                            level: parentFamilyRight.level,
                            name: parentFamily.name,
                            requestId: '',
                            site: parentFamily.site,
                            siteId: parentFamily.siteId,
                        },
                        requestId: '',
                        scope: family.scope,
                    });
                })
            )),
        );
    });

    constructor(
        private _familyService: FamilyService,
        private _actions$: Actions,
        public translate: TranslateService,
        private _errorTranslationService: ErrorTranslationService,
        private readonly store: Store,
    ) { }
}
