import { selectAllUsers$, selectUserLoaded$ } from 'src/app/store/selectors/user-right.selectors';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ReplaySubject, Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserRightService } from 'src/app/services/user-right.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectUserComponent implements OnInit, OnDestroy {

  public userForm = this._fb.group({
    users: this._fb.control<User[]>([], Validators.required)
  });
  public searchControl = this._fb.control('');
  public users!: User[];
  public users$: ReplaySubject<User[]> = new ReplaySubject();
  public usersLoading: boolean;

  private _subscription = new Subscription();

  constructor(
    private userRightService: UserRightService,
    private _fb: FormBuilder,
    private store: Store,
    private _dialogRef: MatDialogRef<SelectUserComponent>,
    @Inject(MAT_DIALOG_DATA) private _usersToFilterOut: User[] = [],
  ) { }

  ngOnInit(): void {
    this._subscription.add(
        this.store.select(selectUserLoaded$).pipe(
            map(loaded => !loaded),
        ).subscribe(
            loading => {
                this.usersLoading = loading;
                this.userForm.controls.users[loading ? 'disable' : 'enable']();
            }
        )
    );
    this.userRightService.checkUserLoaded();
    this._subscription.add(this.store.select(selectAllUsers$).subscribe(
      users => {
        this.users = users.filter(user => !this._usersToFilterOut?.some(({ id }) => user.id === id));
        this.users.sort(
          (a, b) => a.userId.localeCompare(b.userId)
        );
        this._filterUsers();
      }
    ));
    this._subscription.add(
      this.searchControl.valueChanges.subscribe(
        () => this._filterUsers()
      )
    );
  }

  /**
   * Filter the users with the searched text
   */
  private _filterUsers(): void {
    if (!this.users) {
      return;
    }
    const search = this.searchControl.value;
    if (!search) {
      this.users$.next([...this.users]);
    } else {
      this.users$.next([...this.users.filter(
        user => {
          const searchRegex = new RegExp(search, 'i');
          return searchRegex.test(user.userId) || searchRegex.test(user.email);
        }
      )]);
    }
  }

  /**
   * Close the dialog
   */
  public onClickCancel(): void {
    this._dialogRef.close();
  }

  /**
   * Close the dialog & pass the selected users
   */
  public onFormSubmit(): void {
    this._dialogRef.close(this.userForm.value.users);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

}
