<div class="left"></div>
<div class="right">
    <img src="assets/img/undraw_text_field_htlv.png"
         alt="Text field illustration">
    <p class="subtitle">
        {{ title }}
    </p>
    <p class="fill-panel--grey">
        {{ subtitle }}
    </p>
</div>
