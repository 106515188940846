import { fetchAllTrackers, mappingAllTrackersAssets, mappingSomeTrackersAssets, successDissociateTrackerAsset } from '../actions/tracker.action';
import { Tracker } from 'src/app/models/tracker';
import { isEqual } from 'lodash';
import { Action, createReducer, on } from '@ngrx/store';

export interface State {
    data: {
        trackers: { [id: string]: Tracker },
    };
    loaded: boolean;
}

const initialState: State = {
    data: {
        trackers: {},
    },
    loaded: false,
};

const trackersReducer = createReducer(
    initialState,
    on(fetchAllTrackers, (state): State => ({
        ...state,
        loaded: false,
    })),
    on(mappingAllTrackersAssets, (state, action) => {
        return {
            ...state,
            loaded: true,
            data: (() => {
                const data = {
                    trackers: {}
                };

                for (const tracker of action.trackers) {
                    tracker.asset = action.assets[tracker.assetId];
                    if (isEqual(tracker.additionalData, {})) {
                        delete tracker.additionalData;
                    }
                    data.trackers[tracker.id] = tracker;

                }

                return data;
            })()
        };
    }),

    on(mappingSomeTrackersAssets, (state, action) => {
        return {
            ...state,
            data: (() => {
                const data = {...state.data};
                for (const tracker of action.trackers) {
                    // If tracker is associated with asset(otherwise ignore it)
                    if (tracker.assetId) {
                        tracker.asset = action.assets[tracker.assetId];
                        if (isEqual(tracker.additionalData, {})) {
                            delete tracker.additionalData;
                        }
                        data.trackers[tracker.id] = tracker;
                    }
                }
                return data;
            })()
        };
    }),

    on(successDissociateTrackerAsset, (state, action) => {
        return {
            ...state,
            data: (() => {
                const data = {...state.data};
                const tracker = data.trackers[action.trackerId];
                tracker.associated = false;
                delete tracker.asset;
                delete tracker.assetId;
                return data;
            })()
        };
    }),

);

export function reducer(state: State | undefined, action: Action) {
    return trackersReducer(state, action);
}
