import { TranslateService } from '@ngx-translate/core';
import { SnackbarComponent } from '../../components/shared/snackbar/snackbar';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import * as featureActions from '../actions/zone.action';
import { ErrorTranslationService } from 'src/app/services/error-translation.service';
import { Zone } from 'src/app/models/zone';
import { ZoneService } from 'src/app/services/zone.service';
import { Store } from '@ngrx/store';
import { selectSiteState$ } from '../selectors/site.selectors';
import { errorFetchAllZones, errorUpdateZone, fetchAllZones, mapZonesFamilies, successFetchAllZones, successUpdateZone, updateZone } from '../actions/zone.action';
import { selectFamilyRightState$ } from '../selectors/familyRight.selectors';

@Injectable()
export class ZoneEffects {

    public fetchAllZones$ = createEffect(() => { return this._actions$.pipe(
        ofType(fetchAllZones),
        switchMap((action) => combineLatest([
            of(action),
            this.store.select(selectSiteState$).pipe(
                filter(({ loaded }) => loaded),
                map(({ data }) => data),
                take(1),
            ),
        ])),
        switchMap(([, sites]) => combineLatest([
            of(sites),
            this._zoneService.getZones(),
            this.store.select(selectFamilyRightState$).pipe(
                filter((state) => state.loaded),
                map(state => state.data),
                take(1),
            )
        ]).pipe()),
        map(([sites, zones, families]) => successFetchAllZones({ zones, sites, families })),
        catchError(() => of(errorFetchAllZones()))
    ) });

  public successFetchAllZones$ = createEffect(() => { return this._actions$.pipe(
    ofType(successFetchAllZones),
    map((action) => ({
      ...action,
      zones: action.zones.map(
        zone => {
          const { name, company } = action.sites.find(site => site.id === zone.siteId) ?? {};
          return <Zone>{
            ...zone,
            site: name ?? '',
            company: company ?? '',
          };
        }
      )
    })),
    map(({ zones, families }) => mapZonesFamilies({ zones, families })),
  ) });

  public updateZone$ = createEffect(() => { return this._actions$.pipe(
    ofType(updateZone),
    switchMap((actions) => this._zoneService.editZone(actions.zoneId, actions.zone).pipe(
      map((zone: Zone) => {
        const text: string = this.translate.instant('BANNER_SUCCESS_EDIT', { value: zone.name });
        this._snackbar.open(text, 'green-snackbar', 5000);
        this.effectSubject.next('success');
        return featureActions.successUpdateZone({ zone: zone });
      }),
      catchError((error) => {
        this._errorTranslationService.handleError(error, 'BANNER_FAIL_EDIT');
        this.effectSubject.next('error');
        return of(errorUpdateZone());
      }))
    )) });

    protected successUpdateZone$ = createEffect(() => { return this._actions$.pipe(
        ofType(successUpdateZone),
        switchMap((actions) => {
            return this.store.select(selectFamilyRightState$).pipe(
                filter(data => data.loaded),
                map(({ data }) => {
                    return mapZonesFamilies({ zones: [actions.zone], families: data });
                })
            );
        })
    ) });

  public effectSubject: Subject<string>;

  constructor(
    private _zoneService: ZoneService,
    private _actions$: Actions,
    private _snackbar: SnackbarComponent,
    public translate: TranslateService,
    private _errorTranslationService: ErrorTranslationService,
    private store: Store,
  ) {
    this.effectSubject = new Subject<string>();
  }
}
