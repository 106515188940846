<div mat-dialog-title>
    <span>{{ 'ADD_USERS' | translate }}</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>

<form [formGroup]="userForm"
        (ngSubmit)="onFormSubmit()">
    <mat-dialog-content class="form-content">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'NAME' | translate }}</mat-label>
        <mat-select formControlName="users"
                    multiple
                    #multiselect
                    matTooltipClass="multiline-tooltip"
                    [matTooltip]="multiselect.value?.map ? (multiselect.value | extractPropertyPipe: 'userId' | join:'') : ''">
          <mat-select-trigger>
            {{ userForm.get('users').value?.[0]?.userId || '' }}
            <span *ngIf="(userForm.get('users').value?.length || 0) > 1">
              (+{{ userForm.get('users').value?.length - 1 }}&nbsp;{{ (userForm.get('users').value?.length > 2 ? 'others' : 'other') | translate | lowercase }})
            </span>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [placeholderLabel]="'SEARCH' | translate"
                                   [formControl]="searchControl"
                                   [noEntriesFoundLabel]="'NO_OPTIONS_FOUND' | translate">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let user of users$ | async"
                      [value]="user">
            {{ user.userId }}
            <span *ngIf="user.email">&nbsp;|&nbsp;<small>{{ user.email }}</small></span>
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="usersLoading === true">
            <mat-progress-spinner mode="indeterminate"
                                  diameter="12"
                                  strokeWidth="2"></mat-progress-spinner>
            {{ "LOADING_DATA" | translate }}
        </mat-hint>
        <mat-error *ngIf="userForm.controls.users.errors?.required">
            <fa-icon icon="circle-exclamation"></fa-icon>
            {{ "REQUIRED_SELECTION" | translate }}
        </mat-error>
        <mat-error *ngIf="userForm.controls.users.errors?.invalid">
            <fa-icon icon="circle-exclamation"></fa-icon>
            {{ "INVALID_SELECTION" | translate }}
        </mat-error>
      </mat-form-field>
    </mat-dialog-content>

    <div mat-dialog-actions>
        <button mat-dialog-close
                mat-stroked-button
                type="button"
                color="primary">
            {{"CANCEL" | translate}}
        </button>
        <button mat-flat-button
                color="primary"
                type="submit"
                [disabled]="userForm.invalid">
            {{"DIALOG_VALIDATE_OPTION" | translate}}
        </button>
    </div>

  </form>
