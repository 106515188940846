import { createAction, props } from '@ngrx/store';
import { Asset } from 'src/app/models/asset';
import { Tracker, UpdateTracker } from 'src/app/models/tracker';
import { AddDevice } from 'src/app/services/tracker.service';

export const fetchAllTrackers = createAction('[Trackers] fetch all trackers');
export const successFetchAllTrackers = createAction('[Trackers] Success fetch all trackers', props<{ trackers: Tracker[] }>());
export const errorFetchAllTrackers = createAction('[Trackers] Error trackers');

export const createTracker = createAction('[Trackers] create trackers', props<{ payload: AddDevice }>());
export const successCreateTrackers = createAction('[Trackers] Success create trackers', props<{ payload: Tracker[] }>());
export const errorCreateTrackers = createAction('[Trackers] Error create trackers');

export const updateTrackers = createAction('[Trackers] Update tracker', props<{ tracker: UpdateTracker, trackerId: string }>());
export const successUpdateTrackers = createAction('[Trackers] Success update trackers', props<{ payload: Tracker[] }>());
export const errorUpdateTrackers = createAction('[Trackers] Error update tracker');

export const deleteTracker = createAction('[Trackers] delete trackers', props<{ trackerId: string }>());
export const successDeleteTrackers = createAction('[Trackers] success delete trackers', props<{ payload: { id: string }[] }>());
export const errorDeleteTrackers = createAction('[Trackers] Error delete trackers');


export const mappingAllTrackersAssets = createAction('[Trackers] Mapping all trackers and assets', props<{ assets: { [id: string]: Asset }, trackers: Tracker[] }>());
export const mappingSomeTrackersAssets = createAction('[Trackers] Mapping some trackers and assets', props<{ assets: { [id: string]: Asset }, trackers: Tracker[] }>());

export const successAssociateTrackerAsset = createAction('[Tracker] Success associate tracker asset', props<{ trackerId: string, assetId: string }>());
export const successDissociateTrackerAsset = createAction('[Tracker] Success dissociate tracker asset', props<{ trackerId: string, assetId: string; }>());
