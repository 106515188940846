import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {  BehaviorSubject, Observable, Subscription, combineLatest, iif, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    selectContextsState$,
    selectUserSavedContexts$,
    selectCompaniesForContexts$,
    selectParentFamiliesForContexts$,
    selectSitesForContexts$,
    selectFamiliesForContexts$,
    selectDataForContextLoading$,
    contextIsValid$,
} from 'src/app/store/selectors/filter-context.selectors';
import { catchError, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { InitFiltersData, UserFilters } from 'src/app/models/filter-context.model';
import { Site } from 'src/app/models/site';
import { Company } from 'src/app/models/company';
import { Family, ParentFamily } from 'src/app/models/family';
import { TranslateService } from '@ngx-translate/core';
import { fetchDataForContexts, fetchAllUserContexts, applyContextForSession, createUserContext, editUserContext } from 'src/app/store/actions/filter-context.action';
import { FilterContextEffects } from 'src/app/store/effects/filter-context.effects';
import { Tracker } from 'src/app/models/tracker';
import { ListSelectionState } from 'src/app/pipes/list-selection-checkbox-state.pipe';
import { CONTEXT_DIALOG_OPENED, CreateSavedContextPayload, CreateSessionContextPayload, MAX_ASSET_COUNT, UpdateContextPayload } from 'src/app/services/init-filter.service';
import { isEqual, pick } from 'lodash';
import { ContextOverwriteActions, DialogOverwriteActionComponent } from './dialog-overwrite-action.component';
import { SnackbarComponent } from '../../shared/snackbar/snackbar';
import { DEFAULT_DIALOG_OPTIONS as MANAGE_DEFAULT_DIALOG_OPTIONS, DialogManageContextComponent } from '../dialog-manage-context/dialog-manage-context.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AwaitedObservable } from 'src/app/utils/utils';

export const DEFAULT_DIALOG_OPTIONS: MatDialogConfig<DialogConfigureContextComponent> = {
    hasBackdrop: true,
    width: '100vw',
    maxWidth: '750px',
};

@Component({
    selector: 'app-dialog-configure-context',
    templateUrl: './dialog-configure-context.component.html',
    styleUrls: ['./dialog-configure-context.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogConfigureContextComponent implements OnInit, OnDestroy {
    private _mobile: boolean;
    private _mobileLandscape: boolean;

    @HostBinding('class.mobile') get mobileClass() {
        return this._mobile;
    }

    @HostBinding('class.landscape') get mobileLandscapeClass() {
        return this._mobileLandscape;
    }

    @ViewChild('formContainer') private formContainer: ElementRef<HTMLElement>;
    @ViewChild('inputName') private inputName: ElementRef<HTMLInputElement>;

    // Search Controls
    public searchControls: FormGroup<{
        companies: FormControl<string>;
        sites: FormControl<string>;
        parentFamilies: FormControl<string>;
        families: FormControl<string>;
        providers: FormControl<string>;
    }>  = this._fb.group({
        companies: this._fb.control(''),
        sites: this._fb.control(''),
        parentFamilies: this._fb.control(''),
        families: this._fb.control(''),
        providers: this._fb.control(''),
    });

    // Filter Forms
    public initFilterForm: FormGroup<{
        name: FormControl<string>;
        companies: FormControl<Company['id'][]>;
        sites: FormControl<Site['id'][]>;
        parentFamilies: FormControl<ParentFamily['id'][]>;
        families: FormControl<Family['id'][]>;
        providers: FormControl<Tracker['provider'][]>;
    }> = this._fb.group({
        name: this._fb.control<string>(null, {
            asyncValidators: (control: AbstractControl): Observable<ValidationErrors|null> => this._userSavedContexts$?.pipe(
                take(1),
                map(contexts => {
                    const foundContext = contexts.find(
                        ({ name }) => name === control.value
                    );
                    if (foundContext) {
                        return this._data?.id !== foundContext.id ? { alreadyExists: true } : null;
                    }
                    return null;
                }),
                catchError(() => of(null)),
            ) ?? of(null),
        }),
        companies: this._fb.control<Company['id'][]>([]),
        sites: this._fb.control<Site['id'][]>([]),
        parentFamilies: this._fb.control<ParentFamily['id'][]>([]),
        families: this._fb.control<Family['id'][]>([]),
        providers: this._fb.control<Tracker['provider'][]>([]),
    }, {
        asyncValidators: [
            (): Observable<ValidationErrors|null> => {
                return this.totalAssetCount$?.pipe(
                    take(1),
                ).pipe(
                    map(count => {
                        if (count > MAX_ASSET_COUNT) {
                            return { selectionTooLarge: true };
                        }
                        return null;
                    }),
                    catchError(() => of(null)),
                ) ?? of(null);
            },
        ]
    });

    public contextDataLoading$: Observable<boolean> = this.store.select(selectDataForContextLoading$).pipe(
        distinctUntilChanged(),
    );

    // Get companies
    public companies$: Observable<InitFiltersData['companies']> = this.store.select(selectCompaniesForContexts$).pipe(
        distinctUntilChanged(isEqual),
    );

    // Get sites with cascading
    private _sites$: Observable<InitFiltersData['sites']> = this.store.select(selectSitesForContexts$);
    public sites$ = combineLatest([
        this.companies$,
        this.initFilterForm.controls.companies.valueChanges.pipe(
            startWith(this.initFilterForm.value.companies ?? null),
            distinctUntilChanged(isEqual),
        ),
    ]).pipe(
        // Filter on selected companies
        switchMap(([companies, selection]) => iif(
            () => !!selection?.length,
            this._sites$.pipe(
                map(sites => sites.filter(
                    ({ companyId }) => selection.includes(companyId)
                )),
            ),
            this._sites$,
        ).pipe(
            // Get the company from its id
            map(sites => sites.map(
                site => ({
                    ...site,
                    company: companies.find(
                        ({ id }) => id === site.companyId
                    )
                })
            ))
        )),
        // Patch the value to remove values which are not in the cascading selection
        switchMap(sites => this.contextDataLoading$.pipe(
            map(loading => {
                if (!loading) {
                    this._patchFormValueInData(sites, 'sites');
                }
                return sites;
            }),
        )),
        shareReplay(1),
    );

    // Get parent families with cascading
    private _parentFamilies$: Observable<InitFiltersData['parentFamilies']> = this.store.select(selectParentFamiliesForContexts$);
    public parentFamilies$ = combineLatest([
        this.sites$.pipe(
            distinctUntilChanged(isEqual),
        ),
        this.initFilterForm.controls.sites.valueChanges.pipe(
            startWith(this.initFilterForm.value.sites ?? null),
            distinctUntilChanged(isEqual),
        ),
    ]).pipe(
        switchMap(([ sites, selection ]) => this._parentFamilies$.pipe(
            // Filter on cascading sites
            map(parentFamilies => parentFamilies.filter(
                ({ siteId }) => sites.find(({ id }) => id === siteId)
            )),
            // Filter on selected sites
            map(parentFamilies => {
                if (selection?.length) {
                    return parentFamilies.filter(
                        ({ siteId }) => selection.includes(siteId)
                    );
                }
                return parentFamilies;
            }),
            // Get the site from its id
            map(parentFamilies => parentFamilies.map(
                parentFamily => ({
                    ...parentFamily,
                    site: sites.find(
                        ({ id }) => id === parentFamily.siteId
                    ),
                })
            )),
        )),
        // Patch the value to remove values which are not in the cascading selection
        switchMap(parentFamilies => this.contextDataLoading$.pipe(
            map(loading => {
                if (!loading) {
                    this._patchFormValueInData(parentFamilies, 'parentFamilies');
                }
                return parentFamilies;
            }),
        )),
        shareReplay(1),
    );

    // Get parent families with cascading
    private _families$: Observable<InitFiltersData['families']> = this.store.select(selectFamiliesForContexts$);
    public families$ = combineLatest([
        this.parentFamilies$.pipe(
            distinctUntilChanged(isEqual),
        ),
        this.initFilterForm.controls.parentFamilies.valueChanges.pipe(
            startWith(this.initFilterForm.value.parentFamilies ?? null),
            distinctUntilChanged(isEqual),
        ),
    ]).pipe(
        switchMap(([ parentFamilies, selection ]) => this._families$.pipe(
            // Filter the families on the available parent families from cascading
            map(family => family.filter(
                ({ parentFamilyId }) => parentFamilies.find(({ id }) => id === parentFamilyId)
            )),
            // Filter the families on the selection of parent families
            map(families => {
                if (selection?.length) {
                    return families.filter(
                        ({ parentFamilyId }) => selection.includes(parentFamilyId)
                    );
                }
                return families;
            }),
            // Get the parent family from its id
            map(families => families.map(
                family => ({
                    ...family,
                    parentFamily: parentFamilies.find(
                        ({ id }) => id === family.parentFamilyId
                    ),
                })
            )),
        )),
        // Patch the value to remove values which are not in the cascading selection
        switchMap(families => this.contextDataLoading$.pipe(
            map(loading => {
                if (!loading) {
                    this._patchFormValueInData(families, 'families');
                }
                return families;
            }),
        )),
        shareReplay(1),
    );

    private _filteredFamilies$: typeof this._families$ = combineLatest([
        this.families$.pipe(
            distinctUntilChanged(isEqual),
        ),
        this.initFilterForm.controls.families.valueChanges.pipe(
            startWith(this.initFilterForm.value.families ?? null),
            distinctUntilChanged(isEqual),
        ),
    ]).pipe(
        map(([ families, selection ]) => {
            if (!selection?.length) {
                return families;
            }
            return families.filter(({id}) => selection.includes(id));
        }),
        distinctUntilChanged(isEqual),
        shareReplay(1),
    );

    // Get providers with cascading
    public providers$: Observable<InitFiltersData['providers']> = this._filteredFamilies$.pipe(
        map(families => [...new Set(families.map(
            ({ assetCount }) => Object.keys(assetCount)
        ).flat())] as InitFiltersData['providers']),
        // Patch the value to remove values which are not in the cascading selection
        switchMap(providers => this.contextDataLoading$.pipe(
            map(loading => {
                if (!loading) {
                    this._patchFormValueInData(providers, 'providers');
                }
                return providers;
            }),
        )),
        shareReplay(1),
    );

    // Compute the total of assets selected from the cascaded families and the selected providers
    public totalAssetCount$: Observable<number> = combineLatest([
        this._filteredFamilies$,
        this.initFilterForm.controls.providers.valueChanges.pipe(
            startWith(this.initFilterForm.value.providers),
            distinctUntilChanged(isEqual),
            switchMap(selection => iif(
                () => !!selection?.length,
                of(selection),
                this.providers$,
            )),
        ),
    ]).pipe(
        map(([ families, providers ]) => families.reduce(
            (sum, { assetCount }) => {
                for (const provider of providers) {
                    sum += (assetCount[provider] ?? 0);
                }
                return sum;
            }, 0
        )),
        shareReplay(1),
    );

    public readonly ListSelectionState = ListSelectionState;
    public readonly MAX_ASSET_COUNT = MAX_ASSET_COUNT;
    public submiting: boolean;

    public selections: Record<'companies$'|'sites$'|'parentFamilies$'|'families$'|'providers$', Observable<string>>;

    private _userSavedContexts$ = this.store.select(selectUserSavedContexts$);
    public canSelectContext$ = this._userSavedContexts$.pipe(
        map(_ => !!_.length),
    );
    public canCloseDialog$ = this.store.select(contextIsValid$);

    private _subscription: Subscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: UserFilters,
        @Inject(CONTEXT_DIALOG_OPENED) private _dialogOpened$: BehaviorSubject<boolean>,
        private _cd: ChangeDetectorRef,
        private _translate: TranslateService,
        private _fb: FormBuilder,
        private _contextFilterEffect: FilterContextEffects,
        private _dialog: MatDialog,
        private _dialogRef: MatDialogRef<DialogConfigureContextComponent>,
        private store: Store,
        private _snackbar: SnackbarComponent,
        private _breakpointObserver: BreakpointObserver,
    ) { }

    ngOnInit(): void {
        this._subscription.add(
            this._breakpointObserver.observe([
                Breakpoints.Handset,
                Breakpoints.HandsetLandscape,
            ]).subscribe(
                ({ matches, breakpoints }) => {
                    this._dialogRef.updateSize('100vw', matches ? 'calc(100% - 50px)' : 'auto');
                    this._dialogRef.updatePosition(matches ? { bottom: '0' } : null);
                    this._mobile = matches;
                    this._mobileLandscape = breakpoints[Breakpoints.HandsetLandscape];
                    this._cd.markForCheck();
                }
            )
        );

        this._initData();
        this._setSelectionsTexts();
        // For Edit the context
        if (this._data) {
            this.initFilterForm.patchValue({
                name: this._data.name,
                companies: this._data.companies,
                sites: this._data.sites,
                parentFamilies: this._data.parentFamilies,
                families: this._data.families,
                providers: this._data.providers
            });
        }
        this._dialogRef.afterOpened().pipe(
            take(1),
        ).subscribe(
            () => this._dialogOpened$.next(true)
        );
        this._dialogRef.afterClosed().pipe(
            take(1),
        ).subscribe(
            (data) => {
                if (!data?.preventCloseFlag) {
                    this._dialogOpened$.next(false);
                }
            }
        );
    }

    /**
     * Ensure the selection done in the form control is within the available values. Patch the value is necessary.
     * @param data The data which only which the form value must be contained
     * @param formControlName The form control name
     */
    private _patchFormValueInData(
        data: AwaitedObservable<typeof this.sites$>|AwaitedObservable<typeof this.parentFamilies$>|AwaitedObservable<typeof this.families$>|AwaitedObservable<typeof this.providers$>,
        formControlName: keyof Omit<typeof this.initFilterForm.controls, 'name' | 'companies'>
    ) {
        const control = this.initFilterForm.get(formControlName);
        if (!control || !(control.value instanceof Array)) {
            return;
        }
        const findInData = (value) => {
            return (data as any[]).findIndex(
                (item) => {
                    if (typeof item === "string") {
                        return item === value
                    } else if (typeof item === "object") {
                        return item.id === value
                    }
                }
            ) > -1;
        }
        if (control.value.some(
            (value) => !findInData(value)
        )) {
            control.patchValue(control.value.filter(
                (value) => findInData(value)
            ));
        }
    }

    /**
     * Set the text in the selects
     */
    private _setSelectionsTexts() {
        this.selections = {
            companies$: combineLatest([
                this.initFilterForm.controls.companies.valueChanges,
                this.companies$,
            ]).pipe(
                map(
                    ([selection, companies]) => {
                        if (!selection?.length) {
                            return null;
                        }
                        const firstSelectedCompany = companies.find(
                            ({ id }) => selection[0] === id
                        );
                        return this._getSelectionText(firstSelectedCompany?.name, selection);
                    }
                ),
            ),
            sites$: combineLatest([
                this.initFilterForm.controls.sites.valueChanges,
                this._sites$,
            ]).pipe(
                map(
                    ([selection, sites]) => {
                        if (!selection?.length) {
                            return null;
                        }
                        const firstSelectedCompany = sites.find(
                            ({ id }) => selection[0] === id
                        );
                        return this._getSelectionText(firstSelectedCompany?.name, selection);
                    }
                ),
            ),
            parentFamilies$: combineLatest([
                this.initFilterForm.controls.parentFamilies.valueChanges,
                this._parentFamilies$,
            ]).pipe(
                map(
                    ([selection, parentFamilies]) => {
                        if (!selection?.length) {
                            return null;
                        }
                        const firstSelectedCompany = parentFamilies.find(
                            ({ id }) => selection[0] === id
                        );
                        return this._getSelectionText(firstSelectedCompany?.name, selection);
                    }
                )
            ),
            families$: combineLatest([
                this.initFilterForm.controls.families.valueChanges,
                this._families$,
            ]).pipe(
                map(
                    ([selection, families]) => {
                        if (!selection?.length) {
                            return null;
                        }
                        const firstSelectedCompany = families.find(
                            ({ id }) => selection[0] === id
                        );
                        return this._getSelectionText(firstSelectedCompany?.name, selection);
                    }
                ),
            ),
            providers$: combineLatest([
                this.initFilterForm.controls.providers.valueChanges,
                this._translate.onLangChange.pipe(
                    startWith(this._translate.currentLang),
                ),
            ]).pipe(
                map(
                    ([selection]) => {
                        if (!selection?.length) {
                            return null;
                        }
                        const translatedSelection = selection.map(
                            (value) => value || this._translate.instant('NO_DEVICE')
                        );
                        return this._getSelectionText(translatedSelection[0], translatedSelection);
                    }
                ),
            ),
        };
    }

    private _getSelectionText(firstValue: string, selection: any[]) {
        if (selection.length > 1) {
            return this._translate.instant('VALUE_PLUS_N_OTHERS', {
                value: firstValue,
                n: selection.length - 1,
            });
        }
        return firstValue;
    }

    /**
     * initilaize the data
     */
    private _initData() {
        // Fetch data anyway
        this.store.dispatch(fetchDataForContexts());
        this.store.dispatch(fetchAllUserContexts());
        this._subscription.add(
            this.store.select(selectContextsState$).subscribe(
                ({ contextDataLoading, loaded }) => this.initFilterForm[contextDataLoading || !loaded ? 'disable' : 'enable']()
            )
        );
    }

    /**
     * Apply Without Save
     */
    public applyForSession() {
        const { value } = this.initFilterForm;
        const context: CreateSessionContextPayload = {
            companies: value.companies,
            sites: value.sites,
            parentFamilies: value.parentFamilies,
            families: value.families,
            providers: value.providers,
        };
        this._setLoadingForSubmit();
        this.store.dispatch(applyContextForSession({ payload: context }));
    }

    /**
     * Save the context and remember for later usage
     * It is not only valid per session
     */
    public async applyAndSave(): Promise<void> {
        const { value } = this.initFilterForm;
        if (!value.name) {
            return;
        }
        if (!this._data?.id) {
            this._createSavedContext(value);
        } else {
            if (!isEqual(
                pick(this._data, ['companies', 'sites', 'parentFamilies', 'families', 'providers']),
                pick(value, ['companies', 'sites', 'parentFamilies', 'families', 'providers']),
            )) {
                const dialog = this._dialog.open(DialogOverwriteActionComponent);
                dialog.afterClosed().pipe(
                    take(1),
                ).subscribe(
                    (action) => {
                        switch (action) {
                            case ContextOverwriteActions.create:
                                this._userSavedContexts$.pipe(
                                    take(1),
                                ).subscribe(
                                    contexts => {
                                        if (contexts.find(
                                            ({ name }) => name === value.name,
                                        )) {
                                            this._snackbar.open(
                                                this._translate.instant('NAME_ALREADY_EXISTS'),
                                                'orange-snackbar',
                                                5000,
                                            );
                                            this.formContainer.nativeElement?.scrollTo(0, 0);
                                            this.inputName.nativeElement?.focus();
                                            return;
                                        }
                                        this._createSavedContext(value);
                                    }
                                );
                                break;
                            case ContextOverwriteActions.overwrite:
                                this._updateSavedContext(value);
                                break;
                            default:
                                throw new Error(`The action ${action} is not managed`);
                        }
                    }
                );
            } else {
                this._updateSavedContext(value);
            }
        }
    }

    private _setLoadingForSubmit() {
        this.submiting = true;
        this._contextFilterEffect.effectSubject$.pipe(
            filter(({ action }) => action === editUserContext || action === createUserContext || action === applyContextForSession),
            take(1),
        ).subscribe(({ result }) => {
            if (result === 'success') {
                this._dialogRef.close();
            }
            this.submiting = false;
            this._cd.markForCheck();
        });
        this._cd.markForCheck();
    }

    private _updateSavedContext(value: typeof this.initFilterForm.value) {
        const context: UpdateContextPayload = {
            id: this._data.id,
            name: value.name,
            companies: value.companies,
            sites: value.sites,
            parentFamilies: value.parentFamilies,
            families: value.families,
            providers: value.providers,
        };
        this._setLoadingForSubmit();
        this.store.dispatch(editUserContext({ payload: context }));
    }

    private _createSavedContext(value: typeof this.initFilterForm.value) {
        const context: CreateSavedContextPayload = {
            name: value.name,
            companies: value.companies,
            sites: value.sites,
            parentFamilies: value.parentFamilies,
            families: value.families,
            providers: value.providers,
        };
        this._setLoadingForSubmit();
        this.store.dispatch(createUserContext({ payload: context }));
    }

    public resetFilter() {
        const name = this.initFilterForm.value.name;
        this.initFilterForm.reset();
        this.initFilterForm.patchValue({ name });
    }

    /**
   * Triggered when the select all checkbox is clicked
   * @param selected The checkbox selection state
   * @param tyep Form Type
   * @param data$ Observable type
   * Common function for All Filters
   */
    public toggleAll(selected: boolean, formControl: AbstractControl<any[]>, data$: Observable<any[]>, searchControl?: FormControl<string>): void {
        if (selected) {
            data$.pipe(
                take(1),
            ).subscribe(
                data => {
                    const searchedValue = searchControl?.value;
                    const searched = new RegExp(searchedValue ?? '', 'i');
                    let filteredData = [];
                    if (data.every(d => typeof d === 'string')) {
                        filteredData = data.filter(
                            item => searched.test(item)
                        );
                    } else {
                        filteredData = data.filter(
                            item => searched.test(item.name)
                        ).map(
                            item => item.id
                        );
                    }
                    formControl.setValue(filteredData);
                    formControl.markAsDirty();
                }
            );
        } else {
            formControl.setValue([]);
            formControl.markAsDirty();
        }
    }

    public selectExistingContext() {
        this.canCloseDialog$.pipe(
            take(1),
        ).subscribe(
            (canClose) => {
                this._dialogRef.close({
                    preventCloseFlag: true,
                });
                this._dialog.open(DialogManageContextComponent, {
                    ...MANAGE_DEFAULT_DIALOG_OPTIONS,
                    hasBackdrop: canClose,
                    disableClose: !canClose,
                });
            }
        )
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }
}
