import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { SiteService } from 'src/app/services/site.service';
import { fetchAllSites, mappingAllSitesCompanies, mappingSomeSitesCompanies, successFetchAllSites, successUpdateSites } from '../actions/site.action';
import { selectCompaniesState$ } from '../selectors/company.selectors';
import { Injectable } from '@angular/core';
import { successUpdateParentFamilies } from '../actions/parent-family.action';
import { selectParentFamiliesBySitesIds$ } from '../selectors/parent-family.selectors';

@Injectable()
export class SiteEffects {
    public fectchAllSites$ = createEffect(() => { return this.actions$.pipe(
        ofType(fetchAllSites),
        switchMap(() => this.siteService.getAllSites()),
        map((allSites) => successFetchAllSites({ sites: allSites }))
    ) });

    public successFetchAllSites$ = createEffect(() => { return this.actions$.pipe(
        ofType(successFetchAllSites),
        switchMap((action) => this.store.select(selectCompaniesState$).pipe(
            filter(state => state.loaded),
            take(1),
            map(state => mappingAllSitesCompanies({ companies: state.data, sites: action.sites }))
        ))
    ) });

    public successUpdateSites$ = createEffect(() => { return this.actions$.pipe(
        ofType(successUpdateSites),
        switchMap((action) => this.store.select(selectCompaniesState$).pipe(
            filter(state => state.loaded),
            take(1),
            tap(state => this.store.dispatch(mappingSomeSitesCompanies({ sites: action.payload, companies: state.data }))),
            // update children objects
            switchMap(() => this.store.select(selectParentFamiliesBySitesIds$(action.payload.map(site => site.id))).pipe(take(1))),
            map((parentFamilies) => successUpdateParentFamilies({ payload: parentFamilies }))
        )),
    ) });

    public effectSubject: Subject<string | HttpErrorResponse>;
    constructor(
        private siteService: SiteService,
        private actions$: Actions,
        private store: Store,
        public translate: TranslateService,
    ) {
        this.effectSubject = new Subject<string>();
    }
}
